<template>
    <main class="extension-uninstalled">
        <picture class="extension-uninstalled__green-bg">
            <img :src="$assets.illustration.extensionUninstalledBgGreen" alt="fondo verde" />
        </picture>
        <form class="extension-uninstalled__form">
            <section class="extension-uninstalled__body">
                <div class="extension-uninstalled__left">
                    <h1>{{ $lang.pages.extensionDesinstalada.sorry }}</h1>
                    <p no-prefetch class="extension-uninstalled__left -byerror">
                        {{ $lang.pages.extensionDesinstalada.mistake }}
                    </p>
                    <a
                        class="extension-uninstalled__left -reinstall"
                        href="https://chromewebstore.google.com/detail/megadescuentos/gkochegofmljpmgegphbcljdigenjlhd?hl=es"
                        rel="nofolow, noindex"
                    >
                        <img :src="$assets.primary.download" alt="descargar" />
                        {{ $lang.pages.extensionDesinstalada.reinstall }}
                    </a>
                    <p>
                        {{ $lang.pages.extensionDesinstalada.why }}
                    </p>

                    <p>{{ $lang.pages.extensionDesinstalada.select }}</p>

                    <label v-for="(item, index) in reasons" :id="item" :key="index" class="block max-w-max">
                        <input type="checkbox" :name="item" :value="item" :id="item" v-model="form.reasons" />
                        {{ item }}
                    </label>
                </div>
                <!-- <div class="extension-uninstalled__right">
                    <img :src="$assets.illustration.extensionUninstalledOffer" alt="Obtener oferta" />
                </div> -->
            </section>
            <section class="extension-uninstalled__comments">
                <h2>{{ $lang.pages.extensionDesinstalada.other_comments }}</h2>
                <textarea
                    v-model="form.content"
                    :placeholder="$lang.pages.extensionDesinstalada.think"
                ></textarea>
            </section>
            <ButtonGeneric
                ref="submit"
                type="button"
                :loading="loading"
                class="extension-uninstalled__send"
                :info="{
                    text: $lang.pages.extensionDesinstalada.send,
                    handler: onSubmit,
                }"
            ></ButtonGeneric>
        </form>
    </main>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

const props = defineProps({
    propName: {
        type: String,
        default: '',
    },
})

const { $lang } = useNuxtApp()

const loading = ref(false)
const reasons = [
    $lang.pages.extensionDesinstalada.by_mistake,
    $lang.pages.extensionDesinstalada.codes_not_work,
    $lang.pages.extensionDesinstalada.md_not_work,
    $lang.pages.extensionDesinstalada.md_confuse,
    $lang.pages.extensionDesinstalada.savings_useless,
    $lang.pages.extensionDesinstalada.other,
]

const form = reactive({
    content: '',
    reasons: [],
})

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.extensionDesinstalada.title_seo
const description = $lang.pages.extensionDesinstalada.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

type UninstallResponse =
    | {
          error: false
          data: []
          feedback: 'resource_created'
      }
    | { error: true; data: []; feedback: string }

const RootStore = useRootStore()
const AuthStore = useAuthStore()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const onSubmit = async () => {
    loading.value = true

    const body = {
        reasons: form.reasons,
        content: form.content,
    }

    if (body.content.trim().length > 0 || body.reasons.length > 0) {
        try {
            const result = await $fetch<UninstallResponse>(endpoints.general.extension.path, {
                body,
                headers: buildHeaders(),
                baseURL,
                method: 'POST',
                onResponse() {
                    AuthStore.updateActivitiesCount('notifications')
                },
            })

            if (!result.error) {
                RootStore.setSiteNotification({
                    text: 'Gracias por darnos su opinion',
                    type: 'success',
                    dismissButtonText: $lang.pages.extensionDesinstalada.close,
                })

                form.reasons = []
                form.content = ''
            }
        } catch (e) {
            RootStore.setSiteNotification({
                text: $lang.pages.extensionDesinstalada.error,
                type: 'error',
                dismissButtonText: $lang.pages.extensionDesinstalada.close,
            })
        }
    } else {
        RootStore.setSiteNotification({
            text: $lang.pages.extensionDesinstalada.pick_one,
            type: 'help',
            dismissButtonText: $lang.pages.extensionDesinstalada.close,
            duration: 8000,
        })
    }

    loading.value = false
}
</script>

<style lang="postcss" scoped>
.extension-uninstalled {
    @apply relative;
    &__green-bg {
        @apply container left-1/2 hidden -translate-x-1/2 transform lg:absolute lg:block;
        img {
            @apply absolute right-0 h-auto w-1/2 max-w-[700px] object-contain object-right-top;
        }
    }
    &__form {
        @apply container relative z-10 my-10 space-y-6 lg:max-w-screen-lg;
    }
    &__body {
        @apply flex;
    }
    &__left {
        @apply w-full space-y-5 text-gray-800 lg:w-1/2;
        h1 {
            @apply text-4xl font-semibold text-gray-900;
        }
        .-byerror {
            @apply block cursor-pointer text-lg font-medium text-site-primary hover:underline;
        }
        .-reinstall {
            @apply flex w-fit items-center justify-center gap-2 rounded-xl bg-white p-3 font-semibold text-site-primary shadow-lg transition duration-150 hover:shadow-md;
            img {
                @apply h-6 w-6;
            }
        }
    }
    &__right {
        @apply hidden w-1/2 lg:block;
    }
    &__comments {
        @apply flex flex-col gap-y-3;
        h2 {
            @apply text-lg text-gray-800;
        }
        textarea {
            @apply block h-32 w-full rounded-xl border-gray-200 bg-white p-3 px-3 py-1.5 text-base text-gray-800 placeholder-gray-400 ring-site-primary-hover-light transition ease-in-out focus:border-site-primary focus:text-gray-900 focus:ring-1 focus:ring-site-primary lg:hover:ring-1;
        }
    }
    &__send {
        @apply mx-auto bg-site-primary text-white transition;
    }
}
</style>
